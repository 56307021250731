import React from "react";
import woman from "../../../static/banking/woman-office.jpg";
import smartphone from "../../../static/banking/woman-smartphone.jpg";
import network from "../../../static/solutions/network.jpg";
import bank from "../../../static/banking/bank.png";
import case4 from "../../../static/solutions/case4.jpg";
import motorola from "../../../static/partners/motorola.png";
import hp from "../../../static/partners/hp.png";
import oracle from "../../../static/partners/oracle.png";

import Layout from "../../components/Layout/Layout";

const Banking = () => {
  return (
    <Layout>
      <div className="page ">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-paragraph">INDUSTRY</h1>
            <h1 className="section-title">Banking</h1>
            <p className="section-description">
              Our Company is a country leader in financial systems and is proud
              to list all major banks as clients. We provide consulting
              services, process automation, financial systems implementation,
              specialized equipment, 24/7 service desk.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="section-image" src={bank} alt="" />
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">
                Top-notch banking experience, on-line
              </h1>
            </div>
          </div>
          <div className="row page__section --grid-1-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                Having great on-line services is key to engaging modern clients
                into banking operations. They value mobility, accesibility and
                rich features. A modern bank is a bank that is available
                whenever you need it, wherever on the planet you need it.
              </p>
              <p className="section-description">
                We work with our clients towards building a tremendous user
                experience for the end user and also think ahead in order to
                provide new instruments, new rich features that the end users
                enjoy.
              </p>
              <p className="section-description">
                We also create and implement great tools for the bank employees
                so that they cand be efficient, productive and enjoy the
                experience.
              </p>
              <p className="section-description">
                Our company provides great expertise in securing the online
                banking services and ensure that the end user finances and data
                is well protected.
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img
                className="section-description-image"
                src={smartphone}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Smart Banking</h1>
            </div>
          </div>
          <div className="row page__section --grid-2-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                Providing added-value to the end customer through newest
                technologies and services.
              </p>
            </div>
          </div>
          <div className="row page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div className="info-tile  --align-left height-30">
                <div className="icon-container">
                  <i
                    className="tile-icon icon icon-mobile"
                    style={{ fontSize: "4rem" }}
                  />
                </div>
                <p className="section-title-small --align-left ">
                  Digital government transformation
                </p>
                <p className="section-description --align-left ">
                  A smart government needs smart instruments. We are always
                  there to provide the latest technologies for e-transformation.
                </p>
              </div>
            </div>
            <div className="tile-container">
              <div className="info-tile  --align-left height-30">
                <div className="icon-container">
                  <i className="tile-icon icon icon-lock " />
                </div>
                <p className="section-title-small --align-left ">
                  Security and privacy
                </p>
                <p className="section-description --align-left ">
                  Processing personal data requires the highest standards in
                  matters of security and privacy. Our products and the hardware
                  we provide through our venders meet those expectations.
                </p>
              </div>
            </div>
            <div className="tile-container">
              <div className="info-tile  --align-left height-30">
                <div className="icon-container">
                  <i className="tile-icon icon icon-sun-inv " />
                </div>
                <p className="section-title-small --align-left ">
                  Customer Experience
                </p>
                <p className="section-description --align-left ">
                  The customer experience is a decisive factor in whether a
                  product will be used by the general public. Our UI/UX efforts
                  rise up to the challenge.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-2-1 --align-left">
            <div>
              <h1 className="section-sub-title">Secure, modern banking</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={case4} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Modern banking</span>
                  <p className="section-title-small --align-left ">
                    Threat detection
                  </p>
                  <p className="section-description --align-left ">
                    Our Company has a tremendous experience in implementing
                    threat detection systems. One example is ArcSight Enterprise
                    Security Manager (ESM) implemented for ProCredit Bank.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={network} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Modern banking</span>
                  <p className="section-title-small --align-left ">
                    Data Centers
                  </p>
                  <p className="section-description --align-left ">
                    We are very experienced in modernizing networking
                    infrastructures and building data centers. Some of our
                    clients in this are are Victoriabank and Eximbank.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={woman} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Modern banking</span>
                  <p className="section-title-small --align-left ">
                    Front and Back Office Automation
                  </p>
                  <p className="section-description --align-left ">
                    We have worked with all leading banks in Moldova on process
                    automation - both Front-Office and Back-Office.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block --white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Our Partners</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "4rem" }}
                    className="partners-image"
                    src={motorola}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Motorola is the leading global supplier of TETRA equipment
                    and is our partner in Moldova. We supply Motorola equipment
                    to the Police, Border Police, Customs, 112 service, and
                    others.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "7rem" }}
                    className="partners-image"
                    src={hp}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Through our partner HPE, we supply, configure and maintain
                    top of the line servers, storage and networking equipment
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "2rem" }}
                    className="partners-image"
                    src={oracle}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Oracle databases are present in the wide majority of
                    government organizations and our specialists are always
                    ready to offer professional assistance in operating the
                    critical stores of data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Banking;
